import * as React from "react"

const Titlebox = ({ title, caption, children }) => {
  return (
    <div className="lg:w-2/3 flex items-end bb-12">
      <div className="intro-box bg-dark border-r-8 border-primary-500 p-6 rounded-bl-xl lg:-mb-12 lg:p-12">
        {caption && (
          <div className="text-primary-500 text-lg md:text-2xl font-bold mb-4">
            {caption} /
          </div>
        )}
        <h1 className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold mb-4 text-white leading-none">
          {title}
        </h1>
        {children}
      </div>
    </div>
  )
}
export default Titlebox
