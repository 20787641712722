import * as React from 'react'

import { getImage } from "gatsby-plugin-image"

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

const ImgBackground = ({children, image}) => {
    
    const imageFile = getImage(image)
    const bgImage = convertToBgImage(imageFile)
    
    return(

        <BackgroundImage Tag="section" className="page--title" {...bgImage} preserveStackingContext>
        {children}
        </BackgroundImage>

    )
}

export default ImgBackground